import React from "react"
import face1 from "../../assets/images/testimonials/face1.png"
import face2 from "../../assets/images/testimonials/face2.jpg"
import face3 from "../../assets/images/testimonials/face3.jpg"
import face4 from "../../assets/images/testimonials/face4.jpg"

const TestimonialsStyleOne = () => {
  return (
    <div className="testimonials-area pt-100 pb-70 bg-f1f8fb">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">Refferences & Testimonials</span>
          <h2>What People are saying?</h2>
          <p>
            Don’t just take our word for it. Here is some feedback from previous
            partners and clients.
          </p>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="single-testimonials-item">
              <p>
                If you need Design done right from the beginning of the project.
                If you want to do better in your own development projects. If
                you want to transfer your solution to the cloud, and you want to
                do it with high security standards – Arch Forge Solutions is the
                team to go to. I know Paweł as a very good enterprise architect.
                We worked together designing big system landscapes, and
                integrating many individual projects into a large management
                program. I enjoyed our deep discussions over module integration
                and software strategy.
              </p>
              <div className="client-info">
                <div className="d-flex justify-content-center align-items-center">
                  <img src={face2} alt="about" />
                  <div className="title">
                    <h3>Giorgi Ananidze</h3>
                    <span>
                      Senior IT Architect at a large Consulting Company
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6">
            <div className="single-testimonials-item">
              <p>
                I had the pleasure to work with Pawel from Arch Forge Solutions
                for few years on several projects in the financial industry. It
                is rare that you come across a standout architect like Pawel.
                His deep knowledge of Software Design and Software Development
                overall helped us to increase development speed and solve
                complex challenges. Not only was he able to bridge business with
                technical developers, but would also advise on solution strategy
                and product development. I can without a doubt recommend his
                services.
              </p>
              <div className="client-info">
                <div className="d-flex justify-content-center align-items-center">
                  <img src={face3} alt="about" />
                  <div className="title">
                    <h3>Grzegorz Sadzinica</h3>
                    <span>Senior SAP Consultant at Lloyds Bank</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6">
            <div className="single-testimonials-item">
              <p>
                Arch Forge can provide high quality technical solutions in
                software development and architecture design. I worked with
                Pawel personally for several years in Java Backend and Frontend
                projects. He has excellent technical knowledge and very good
                communication skills. I believe every project team that has him
                as chief architect will be very happy with his professionalism
                and expertise.
              </p>
              <div className="client-info">
                <div className="d-flex justify-content-center align-items-center">
                  <img src={face1} alt="about" />
                  <div className="title">
                    <h3>Gabriel Maks</h3>
                    <span>Head of Delivery at IT House VM.pl</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6">
            <div className="single-testimonials-item">
              <p>
                Due to the complexity of our IT projects, high quality software
                architecture has always been important to Axxiome Health. Thanks
                to many years of working with AFS, we've been able to build
                complex IT products for our customers. Pawel P. helped us
                introduce IT Architecture Management processes, and worked as
                our main architect for software projects. AFS expertise has been
                very helpful, especially in technical discussions with
                client-side architects, and in pre-project analysis.
              </p>
              <div className="client-info">
                <div className="d-flex justify-content-center align-items-center">
                  <img src={face4} alt="about" />
                  <div className="title">
                    <h3>Paweł Midoń</h3>
                    <span>CEO at Axxiome Health</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestimonialsStyleOne
